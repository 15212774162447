import React, { useRef, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import qs from "query-string"
import { signUp } from "@http/AuthRepository"
import { setToken } from "@http/client"
import {
  detectMob,
  useInput,
  isInputEmpty,
  isPasswordVisible,
  isEmptyObject,
} from "@src/Helper"
import RegisterInputEmail from "./RegisterInputEmail"
import RegisterInputUsername from "./RegisterInputUsername"
import RegisterInputPassword from "./RegisterInputPassword"
import RegisterInputTermsConditions from "./RegisterInputTermsConditions"

const Register = ({ location }) => {
  // Hooks
  const usernameRef = useRef(null)
  const emailRef = useRef(null)
  const passwordRef = useRef(null)
  const eyeRef = useRef(null)
  const username = useInput("")
  const email = useInput("")
  const password = useInput("")
  const agreeTC = useInput(false)
  const isLoading = useInput(false)
  const error = useInput({})

  useEffect(() => {
    if (typeof qs.parse(location.search).username !== undefined) {
      username.setValue(qs.parse(location.search).username)
    }
  }, [])

  // Custom Functions
  const handleSignUp = () => {
    error.setValue({ ...error.value })
    isLoading.setValue(true)
    signUp(username.value, email.value, password.value, agreeTC.value)
      .then(res => {
        setToken(res.data.access_token)
        isLoading.setValue(false)
        error.setValue({})
        navigate("/dashboard")
      })
      .catch(err => {
        if (err.response.data.status_code === 422) {
          error.setValue({
            ...error.value,
            username: err.response.data.errors.username
              ? err.response.data.errors.username
              : null,
            email: err.response.data.errors.email
              ? err.response.data.errors.email
              : null,
            password: err.response.data.errors.password
              ? err.response.data.errors.password
              : null,
            agreeTC: err.response.data.errors.terms_and_conditions
              ? err.response.data.errors.terms_and_conditions
              : null,
          })
        }
        isLoading.setValue(false)
      })
  }

  // JSX
  if (detectMob()) {
    return (
      <div id="m-register" className="register">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="register__title heading-3">Buat Akun Sociopipe</h1>
              <form
                className="register__form"
                onSubmit={e => {
                  e.preventDefault()
                  handleSignUp()
                }}
              >
                <RegisterInputUsername
                  usernameRef={usernameRef}
                  username={username}
                  isInputEmpty={isInputEmpty}
                  isEmptyObject={isEmptyObject}
                  error={error}
                  autofocus={true}
                />
                <RegisterInputEmail
                  emailRef={emailRef}
                  email={email}
                  isInputEmpty={isInputEmpty}
                  isEmptyObject={isEmptyObject}
                  error={error}
                />
                <RegisterInputPassword
                  passwordRef={passwordRef}
                  eyeRef={eyeRef}
                  password={password}
                  isInputEmpty={isInputEmpty}
                  isEmptyObject={isEmptyObject}
                  isPasswordVisible={isPasswordVisible}
                  error={error}
                />
                <RegisterInputTermsConditions
                  agreeTC={agreeTC}
                  isEmptyObject={isEmptyObject}
                  error={error}
                />
                <button
                  type="submit"
                  className="register__form__button button button-md button-primary"
                  disabled={isLoading.value}
                >
                  {isLoading.value ? (
                    <span
                      className="spinner-border spinner-border-sm ms-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <>Daftar</>
                  )}
                </button>
              </form>
              <p className="register__go-to__login body">
                Sudah memiliki akun?{" "}
                <Link className="register__go-to__login__link" to="/login">
                  Masuk sekarang
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="register">
        <div className="container pad-t-175">
          <div className="row">
            <div className="col-6">
              <h1 className="register__title heading-3">Buat Akun Sociopipe</h1>
              <form
                className="register__form"
                onSubmit={e => {
                  e.preventDefault()
                  handleSignUp()
                }}
              >
                <RegisterInputUsername
                  usernameRef={usernameRef}
                  username={username}
                  isInputEmpty={isInputEmpty}
                  isEmptyObject={isEmptyObject}
                  error={error}
                  autofocus={true}
                />
                <RegisterInputEmail
                  emailRef={emailRef}
                  email={email}
                  isInputEmpty={isInputEmpty}
                  isEmptyObject={isEmptyObject}
                  error={error}
                />
                <RegisterInputPassword
                  passwordRef={passwordRef}
                  eyeRef={eyeRef}
                  password={password}
                  isInputEmpty={isInputEmpty}
                  isEmptyObject={isEmptyObject}
                  isPasswordVisible={isPasswordVisible}
                  error={error}
                />
                <RegisterInputTermsConditions
                  agreeTC={agreeTC}
                  isEmptyObject={isEmptyObject}
                  error={error}
                />
                <button
                  type="submit"
                  className="register__form__button button button-md button-primary"
                  disabled={isLoading.value}
                >
                  {isLoading.value ? (
                    <span
                      className="spinner-border spinner-border-sm ms-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <>Daftar</>
                  )}
                </button>
              </form>
              <p className="register__go-to__login body">
                Sudah memiliki akun?{" "}
                <Link className="register__go-to__login__link" to="/login">
                  Masuk sekarang
                </Link>
              </p>
            </div>
            <div className="col-5 offset-1 position-relative">
              <div className="position-relative">
                <StaticImage
                  className="register__image"
                  src="../../../images/auth/auth-register-login.png"
                  alt="register Image"
                  placeholder="tracedSVG"
                  layout="fullWidth"
                />
                {/* <div className="register__quote">
                  <div className="triangle-shape"></div>
                  <div className="register__quote__box">
                    <h3 className="register__quote__box__text heading-5">
                      Create Your Own World With Sociopipe
                    </h3>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Register
