import React from "react"
import {Link} from "gatsby"
import { detectMob } from "@src/Helper"

const RegisterInputTermsConditions = ({ agreeTC, isEmptyObject, error }) => {
  if (detectMob()) {
    return (
      <div className="register__form__check form-check">
        <input
          type="checkbox"
          className="register__form__check__input form-check-input"
          id="inputCheck"
          onChange={() => agreeTC.setValue(!agreeTC.value)}
        />
        <label
          className="register__form__check__label form-check-label caption"
          htmlFor="inputCheck"
        >
          Saya telah membaca dan setuju dengan segala <Link className="text-apricot-60" to="/privacy-policy">Kebijakan Privasi</Link> dan <Link className="text-apricot-60" to="/terms-and-conditions">Syarat & Ketentuan</Link>
          {/* I agree to all Sociopipe terms and conditions */}
        </label>
        {isEmptyObject(error.value, error.value.agreeTC) ? (
          <></>
        ) : (
          <span className="error-message caption">{error.value.agreeTC}</span>
        )}
      </div>
    )
  } else {
    return (
      <div className="register__form__check form-check">
        <input
          type="checkbox"
          className="register__form__check__input form-check-input"
          id="inputCheck"
          onChange={() => agreeTC.setValue(!agreeTC.value)}
        />
        <label
          className="register__form__check__label form-check-label caption"
          htmlFor="inputCheck"
        >
          Saya telah membaca dan setuju dengan segala <Link className="text-apricot-60" to="/privacy-policy">Kebijakan Privasi</Link> dan <Link className="text-apricot-60" to="/terms-and-conditions">Syarat & Ketentuan</Link>
        </label>
        {isEmptyObject(error.value, error.value.agreeTC) ? (
          <></>
        ) : (
          <span className="error-message caption">{error.value.agreeTC}</span>
        )}
      </div>
    )
  }
}

export default RegisterInputTermsConditions
