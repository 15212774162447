import React from "react"
import { detectMob } from "@src/Helper"

const RegisterInputUsername = ({
  usernameRef,
  username,
  isInputEmpty,
  isEmptyObject,
  error,
  autofocus,
}) => {
  if (detectMob()) {
    return (
      <div className="register__form__username">
        <label
          htmlFor="inputUsername"
          className="register__form__username__label label-sm"
        >
          Username
        </label>
        <input
          type="text"
          className="register__form__username__input input-md"
          id="inputUsername"
          value={username.value}
          spellCheck={false}
          placeholder="Masukkan username kamu"
          ref={usernameRef}
          autoFocus={autofocus}
          onChange={e => {
            isInputEmpty(usernameRef)
            username.setValue(e.target.value)
          }}
        />
        {isEmptyObject(error.value, error.value.username) ? (
          <></>
        ) : (
          <span className="error-message caption">{error.value.username}</span>
        )}
      </div>
    )
  } else {
    return (
      <div className="register__form__username">
        <label
          htmlFor="inputUsername"
          className="register__form__username__label label-sm"
        >
          Username
        </label>
        <input
          type="text"
          className="register__form__username__input input-md"
          id="inputUsername"
          value={username.value}
          spellCheck={false}
          placeholder="Masukkan username kamu"
          ref={usernameRef}
          autoFocus={autofocus}
          onChange={e => {
            isInputEmpty(usernameRef)
            username.setValue(e.target.value)
          }}
        />
        {isEmptyObject(error.value, error.value.username) ? (
          <></>
        ) : (
          <span className="error-message caption">{error.value.username}</span>
        )}
      </div>
    )
  }
}

export default RegisterInputUsername
