import * as React from "react"
import Register from "@components/Auth/Register/Register"
import Seo from "@components/seo"

const RegisterPage = ({location}) => (
  <>
    <Seo title="Register" />
    <Register location={location} />
  </>
)

export default RegisterPage
